<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/check' }">点检计划</el-breadcrumb-item>
          <el-breadcrumb-item>计划详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="点检代号：" prop="taskCode">
                {{ form.taskCode }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="点检日期：" prop="name">
                {{ form.startDate ? dayjs(form.startDate).format('YYYY-MM-DD'):'' }}
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 巡视点信息配置 -->
          <div class="form-title">巡视点信息</div>
          <el-table :data="patrols" style="width: 100%;margin-bottom: 20px;">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="departmentName" label="所属部门"></el-table-column>
            <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
          </el-table>

          <!-- 点检人员 -->
          <div class="form-title">点检人员</div>
          <div v-for="(item, key) in ruleStaffDetail" :key="key" class="config-box">
            <div class="title">
              <div style="display: flex;">
                <span class="iconfont icon-peizhi" style="font-size: 20px;margin-right: 5px;"></span>
                <span> 配置 {{key + 1}} </span>
              </div>
            </div>
            <el-form-item label="点检员：" prop="name">
              <div class="staffBox">
                <el-tag v-for="staff in item.staffInfos" :key="staff.staffId" style="margin-right: 4px;">
                  {{ staff.staffName }}
                </el-tag>
              </div>
            </el-form-item>

            <div class="setWeek">
              <el-form-item label="工作时间：">
                <div class="box" v-for="(item, index) in item.frequencyInfos" :key="index">
                  <div class="week-title">
                    <span v-for="(tItem, tIndex) in item.frequencyTimeInfos" :key="tIndex">
                      {{ dateSecond(tItem.startTime) }} ~ {{ dateSecond(tItem.endTime) }}
                    </span>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/check')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      loading: false,
      form: {
        startDate: 0,
        endDate: 0,
      },
      patrols: [],
      row: {},
      ruleStaffDetail: []
    }
  },
  methods: {
    dayjs,
    dateSecond(val) {
      let s = val % 60
      let _s = val - s

      let m = (_s / 60) % 60

      let h = (_s / 60 - m) / 60
      return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
    },
    loadBaseDetail() {
      this.$ajax.post('rulebaseDetail', {
        taskRuleId: this.row.id
      }).then(res => {
        sessionStorage.setItem("detail", true);
        this.$set(this.form, 'taskRuleName', res.data.taskRuleName)
        this.form.startDate = res.data.startDate
        this.form.endDate = res.data.endDate
        this.form.taskCode = res.data.taskCode
        this.form.departmentName = res.data.departmentName
        this.loadPatrolDetail()
      })
    },

    loadPatrolDetail() {
      this.loading = true
      this.$ajax.post('rulePatrolDetail', {
        taskRuleId: this.row.id
      }).then(res => {
        res.data.forEach(v=>{
          this.patrols.push({
            departmentName: this.form.departmentName,
            patrolName: v.patrolName
          })
        })
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },

    loadStaffDetail() {
      this.$ajax.post('ruleStaffDetail', {
        taskRuleId: this.row.id
      }).then(res => {
        // this.$set(this.form, 'patrols', this.patrolsString(res.data))
        this.ruleStaffDetail = res.data
      })
    },

    patrolsString(arr) {
      let d = []
      if (arr) {
        arr.forEach(item => {
          d.push(item.patrolName)
        })
      }
      return d.toString();
    }
    // rulePatrolDetail
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push('/check')
      return
		}
    this.row = this.$route.params.row
    this.loadBaseDetail()
    this.loadStaffDetail()
  },
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.config-box {
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border: 1px solid #ebeef5;
  border-radius: 10px;
  margin: 20px 0;
  .title {
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
  }
}
.staffBox {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  margin-right: 140px;
  padding: 4px;
  min-height: 40px;
}
.setWeek {
  border-top: 0;
  .week-title {
    font-size: 14px;
    span {
      margin-right: 20px;
    }
  }
  .title {
    font-size: 14px;
    padding-left: 10px;
    line-height: 30px;
    background-color: #f6f7fb;
  }
  .box {
    padding: 0 10px;
    margin-bottom: 6px;
  }
  .box:last-child {
    margin: 0;
  }
}
</style>